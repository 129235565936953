import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { Col, Row, Dialog, Field, Icon, Button, ActionSheet, ShareSheet, Overlay, Loading, Notify, Cell, CellGroup, Popup, Picker, Progress, Popover, ImagePreview, Swipe, SwipeItem, Tab, Tabs } from 'vant';
import 'vant/lib/index.css'
import { ethers } from 'ethers'
import global from '@/assets/js/global.js'
import { abi } from "@/assets/js/contractMethods.js";
import VueI18n from 'vue-i18n';
import * as echarts from "echarts";
import '@/assets/font/font.css';
const wqConfig = require("../public/wqpackage/json/userConfig.json")

var Web3 = require('web3');
var web3 = new Web3(Web3.givenProvider)

Vue.config.productionTip = false

Vue.prototype.$echarts = echarts
Vue.prototype.$JinConfig = wqConfig
Vue.prototype.$axios = axios
Vue.prototype.$ethers = ethers
Vue.prototype.$web3 = web3
Vue.prototype.$Dialog = Dialog
Vue.prototype.$global = global
Vue.prototype.$abi = abi
Vue.prototype.$setItem = (data) => {
  return localStorage.setItem("wallet", data);
}
Vue.prototype.$setlang = (data) => {
  return localStorage.setItem("lang", data);
}
Vue.prototype.$getWallet = () => {
  return localStorage.getItem("wallet") || '';
}

Vue.prototype.showSuccessMessage = (message, confirmButtonText, cancelButtonText, callback) => {
  if (!message) return;
  console.log('confirmButtonText', confirmButtonText);
  Dialog
    .alert({
      title: "",
      confirmButtonText: confirmButtonText || '确认',
      cancelButtonText: cancelButtonText || '取消',
      message: `${message}`,
    })
    .then(() => {callback&&callback()});
}

Vue.prototype.showSuccessMessageNotify = (type, message) => {
  if (!message) return;
  Notify({ type, message });
}

Vue.prototype.$ImagePreview = ImagePreview



Vue.use(VueI18n);
if(!localStorage.getItem('lang') || localStorage.getItem('lang') == 'en' || localStorage.getItem('lang') == 'zh' || localStorage.getItem('lang') == 'zh-ft') {
  localStorage.setItem('lang', '简体中文')
}
const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || '简体中文', //使用localStorage缓存到本地，当下次使用时可默认当前使用语言
  messages: {

    '简体中文': require('./lang/简体中文'),
    

    '英语': require('./lang/英语 English'),
  }
})


Vue.use(Col);
Vue.use(Row);
Vue.use(Dialog);
Vue.use(Field);
Vue.use(Icon);
Vue.use(Button);
Vue.use(ActionSheet);
Vue.use(Overlay);
Vue.use(Loading);
Vue.use(Notify);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Progress);
Vue.use(Popover);
Vue.use(ImagePreview);
Vue.use(ShareSheet);
Vue.use(Swipe,);
Vue.use(SwipeItem);
Vue.use(Tab);
Vue.use(Tabs);



window.vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
