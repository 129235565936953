import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home/index')
  },
  {
    path: '/Private',
    name: 'Private',
    component: () => import(/* webpackChunkName: "about" */ '../views/Private/index')
  },
  {
    path: '/Mint',
    name: 'Index',
    component: () => import(/* webpackChunkName: "about" */ '../views/Index/index')
  },
  {
    path: '/Swap',
    name: 'Index',
    component: () => import(/* webpackChunkName: "about" */ '../views/Swap/index')
  },

  
  {
    path: '/WhitePaper',
    name: 'WhitePaper',
    component: () => import(/* webpackChunkName: "about" */ '../views/WhitePaper/index')
  },
  {
    path: '/Develop',
    name: 'Develop',
    component: () => import(/* webpackChunkName: "about" */ '../views/Develop/index')
  },
  {
    path: '/Foundation',
    name: 'Foundation',
    component: () => import(/* webpackChunkName: "about" */ '../views/Foundation/index')
  },
  {
    path: '/Publicize',
    name: 'Publicize',
    component: () => import(/* webpackChunkName: "about" */ '../views/Publicize/index')
  },

  
  {
    path: '/Config',
    name: 'Config',
    component: () => import(/* webpackChunkName: "about" */ '../views/Config/index')
  },
]

const router = new VueRouter({
  routes
})

export default router
