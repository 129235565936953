import Vue from 'vue'
import Vuex from 'vuex'
import { GetWalletAddress } from '@/assets/js/contractMethods'
import contractToken from '../views/Index/js/contract'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    wallet: getWallet(),
    lang: getLang() || '简体中文',
    loading: false,
    loadingText: '执行中',
    // bindAddress: contractToken.address,
    bindAddress: '',
    bindState: false,
    bindLoadingState: true,
    bindWindowState: false,
    isFristLoad: true,
    approveState: false,
    language: getLang() || '简体中文',
    currentChainId: '',
    userChainRPC: 'https://go.getblock.io/e6408977cda949ec97c7238bc7da7c9b',
    userChainId: 56
    // userChainId: 97
  },
  mutations: {
    SET_language(state, lang) {
      setLang(lang)
      state.language = lang
    },
    SetIsFristLoad(state, type) {
      state.isFristLoad = type
    },
    SetLoading(state, type) {
      state.loading = type
    },
    SetLoadingText(state, type) {
      state.loadingText = type
    },
    SetWalletAddress(state, address) {
      setWallet(address)
      state.wallet = address
    },
    SetLang(state, lang) {
      setLang(lang)
      state.lang = lang
    },
    SetBindState(state, type) {
      state.bindState = type
    },
    SetBindAddress(state, address) {
      // console.log('address', address);
      // state.bindAddress = address || '0x2b1e67B3A6b91d9be8a641e190DcbbcBC156bB13'
      state.bindAddress = address || ''
    },
    SetBindWindowState(state, type) {
      state.bindWindowState = type
    },
    SetBindLoadingState(state, type) {
      state.bindLoadingState = type
    },
    SetApproveState(state, type) {
      state.approveState = type
    },
    SetCurrentChainId(state, id) {
      state.currentChainId = id
    },

  },
  actions: {
  },
  modules: {
  }
})

export function setWallet(data) {
  return localStorage.setItem("wallet", data);
}
export function getWallet() {
  return localStorage.getItem("wallet") || '';
}
export function getContractWallet() {
  const address = GetWalletAddress()
  return address;
}
export function getLang() {
  return localStorage.getItem("lang") || '';
}
export function setLang(lang) {
  return localStorage.setItem("lang", lang);
}