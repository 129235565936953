import Vue from 'vue'
import VueI18n from 'vue-i18n';

if (!localStorage.getItem('lang') || localStorage.getItem('lang') == 'en' || localStorage.getItem('lang') == 'zh' || localStorage.getItem('lang') == 'zh-ft') {
    localStorage.setItem('lang', '简体中文')
}

Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || '简体中文', //使用localStorage缓存到本地，当下次使用时可默认当前使用语言
    messages: {

        '简体中文': require('./简体中文'),

        '英语': require('./英语 English'),

    }
})
export default {
    t: (args) => {
        return i18n.tc.call(i18n, args);
    }
} 
