<style lang="scss" scoped>
.page-header {
    width: 100%;
    height: 45px;
    color: #fff;
    position: fixed;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 11;
    transition: all .3s;
    font-size: 14px;
    overflow: hidden;
    // background-image: linear-gradient(to bottom, #03162a, rgba(0, 0, 0, 0));
    // background-color: #fff;
    // border-radius: 0px 0px 4px 4px;
    // box-shadow: 0px 2px 4px rgba(0, 0, 0, .3);
    padding: 0 10px 0 10px;

    // height: 72px;
    // background-color: #1A2660;
    background-color: #0C131D;
    &.active {
        height: 72px;
    }

    .page-header_box {
        // max-width: 1200px;
        // margin: 0 auto;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .page-header_logo {
        width: 72px;
        // height: 100%;
        height: 50px;
        position: relative;
        top: 4px;

        img {
            width: 100%;
            height: 100%;
            margin-left: -10px;
        }
    }

    .page-header_menu {
        height: 100%;

        // margin-left: 150px;
        &,
        & * {
            transition: all .3s;
        }

        .page-header_menu-box {
            display: flex;
            height: 100%;

            // opacity: 1;

            align-items: center;
            justify-content: center;

            .page-header_menu-cell {
                // width: 100px;
                height: 60%;
                border-radius: 6px;
                overflow: hidden;

                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 5px;
                    cursor: pointer;
                    transition: all .3s;

                    &:hover,
                    &.active {
                        background-color: rgba(60, 186, 169, 1);
                    }

                    &:active {
                        color: #8154fe;
                    }
                }
            }

            .page-header_menu-language-icon {
                img {
                    width: 22px;
                    height: 22px;
                    margin: 4px 4px 0 0;
                }
            }

            .page-header_menu-list-icon {
                padding-left: 8px;

                img {
                    width: 22px;
                    height: 22px;
                    margin: 4px 4px 0 0;
                }
            }


            .page-header_menu-language {
                display: flex;
                align-items: center;
                cursor: pointer;
                height: 20px;
                margin: 0px 5px;
                font-size: 10px;

                &:hover {
                    img {
                        border-color: #fff;
                    }
                }

                img {
                    height: 100%;
                    width: 100%;
                    border: 1px solid transparent;
                    border-radius: 50%;
                    transition: all .3s;
                }
            }
        }

    }
}

</style>

<style lang="scss">
.van-popover--dark {
    .van-popover__content {
        background-color: #000 !important;
    }
}
</style>
<template>
    <div class="page-header" :class="{ active: !navOpen }" :style="$global.moduleShowAnimation(3)">
        <div class="page-header_box">
            <div class="page-header_logo font-color__theme display-flex__align-center font-weight__700 font-size__16px" @click="onSelect(actions[0])">
                <!-- <img :src="logoImage" alt=""> -->
                <img src="../assets/img/redesign/logo.png" alt="">
                <!-- <span class="padding__5px font-color__theme-glod">MFT</span> -->
            </div>

            <div class="page-header_menu">

                <div class="page-header_menu-box">

                    <!-- <div v-for="(item, index) of menu" :key="index" class="page-header_menu-cell">
                            <a v-if="!item.unshow" @click="$router.push(item.router)" :class="{active: item.router == active}">{{ $t(`head.${item.langKey}`) }}</a>
                    </div> -->
                    <!-- <div class="page-header_menu-language" @click="cutLanguage('en')">
                        {{ language }}
                    </div> -->
                    <ContentWallet style="margin-right: 20px;"></ContentWallet>

                    <div class="page-header_menu-language-icon margin-right__10px" @click="cutLanguage">
                        <img :src="languageImage" alt="">
                    </div>




                    <van-popover popover-class="c-class" v-model="showPopover" theme="dark" placement="bottom-end" trigger="click"
                        @select="onSelect" :actions="actions">
                        <template #reference>
                            <div class="page-header_menu-list-icon">
                                <img :src="ListIcon" alt="">
                            </div>
                        </template>
                        <template #default>
                            <div v-for="(item, index) of actions" :key="index" role="menuitem" class="van-popover__action" @click="onSelect(item)">
                                <div class="van-popover__action-text van-hairline--bottom">
                                    {{ $t(`${item.textKey}`) }}
                                </div>
                            </div>
                        </template>
                    </van-popover>



                </div>
            </div>
        </div>



        <!-- <div style="position: absolute;z-index: -1000;opacity: 0;visibility: hidden;">
            <audio controls autoplay>
            <source src="../assets/static/go-time.mp3" type="audio/mpeg">
                Your browser does not support the audio element.
            </audio>
        </div> -->
    </div>
</template>


<script>
import logoImage from '@/assets/img/redesign/logo.png'
import languageImage from '@/assets/img/redesign/lang.png'
import ListIcon from '@/assets/img/redesign/more.png'
import ContentWallet from "@/components/ContentWallet.vue";

export default {
    name: 'page-header',
    props: {
        columns: {
            type: Array,
            default: []
        }
    },
    components: {
        ContentWallet
    },
    computed: {
        active() {
            return this.$route.fullPath
        },
        language() {
            const language = this.$store.state.language || '简体中文'
            const item = this.columns.find(record => record.value == language) || {}
            return item.text || 'English'
        },
        navOpen() {
            // if(this.active != '/') {
            //     return true
            // }else 
            if (this.scrolleTop == 100) {
                return true
            } else {
                return false
            }
        }
    },
    data() {
        return {
            logoImage,
            languageImage,
            ListIcon,
            menu: [{
                langKey: 'home',
                router: '/',
                id: '#',
                url: ''
            }, {
                langKey: 'czzzjh',
                router: '/Join',
                id: '#Join',
                url: ''
            }, {
                langKey: 'mint',
                router: '/Mint',
                id: '#Mint',
                url: '',
                unshow: true
            }],
            scrolleTop: 0,
            showPopover: false,
            actions: [
                { textKey: 'guanwang2.us111', text: '主页', url: '/' },
                { textKey: 'guanwang2.us888', text: '发展', url: '/Develop' },
                { textKey: 'guanwang2.us222', text: '白皮书', url: '/WhitePaper' },
                // { textKey: 'guanwang2.us333', text: '基金会', url: '/Foundation' },
                // { textKey: 'guanwang2.us444', text: '宣发', url: '/Publicize' },
                { textKey: 'guanwang2.us555', text: '采矿', url: '/Mint' },
                // { textKey: 'Swap', text: 'Swap', url: '/Swap' },
                // { textKey: 'guanwang2.us666', text: '联系我们', isCallUs: true },
                // { textKey: 'guanwang2.us888', text: '切换语言', isCutLanguage: true }
            ],
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll, true)
    },
    methods: {
        cutLanguage(lang) {
            this.$emit("show")
        },
        handleScroll() {
            //方法一
            var top = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)

            if (top >= 100) {
                this.scrolleTop = 100
            } else {
                this.scrolleTop = top
            }
        },
        onSelect(e) {
            // console.log(e)
            if (e.isCutLanguage) {
                this.cutLanguage()
            } else if (e.isCallUs) {
                this.$emit("callUs")
            } else {
                this.$router.push(e.url)
            }
            this.showPopover = false
        }
    }

}
</script>